<template>
  <div class="book">
    <!-- 标题区域 -->
    <div class="title-wrap">
      <span @click="language_active('english')">English</span>
      <span @click="language_active('chinese')">中文</span>
    </div>

    <!-- logo菜单区域 -->
    <nav-bar @navBtn="navBtn"/>
    <!-- 菜单导航区域 -->
    <menu-page ref="enu" :flag="flag" v-show="menuShow == 1" />

    <div :class="flag == 1 ? 'content-active-wrap' : 'content-wrap'"  v-show="menuShow == 0">
      <!-- 背景图片区域 -->
    <div class="bg-img-wrap">
      <img src="@/assets/icon/bg-book.png" alt="" />
    </div>

    <!-- 标题图标区域 -->
    <div class="title-icon-wrap">
      <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">{{
        flag == 1
          ? "Book from the Ground: From Point to Point"
          : "地书：从点到点"
      }}</span>
      <img src="@/assets/icon/title-icon2.png" alt="" />
      <img src="@/assets/icon/title-icon3.png" alt="" />
      <img src="@/assets/icon/title-icon4.png" alt="" />
    </div>

    <!-- 简介内容区域 -->
    <div class="presentation-wrap">
      <div v-show="flag == 1">
        <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{presentationTextActive}}</span>
        <img class="xiaoren-icon" src="../assets/icon/xiaoren.png" alt="" />
        <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{presentationTextActive3}}</span>
      </div>
      <p v-show="flag == 2">{{presentationText}}</p>
      <p :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{ flag == 1 ? presentationTextActive2 : presentationText2 }}</p>
    </div>

    <!-- 视屏播放区域 -->
    <div class="video-wrap" @click="jump">
      <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-4.png" alt="" />
      <img src="@/assets/icon/play-button2.png" alt="" />
    </div>

    <!-- 地书背景图片区域 -->
    <div class="book-bg-wrap">
      <img src="@/assets/icon/book-bg2.png" alt="" />
    </div>
    </div>

    <div v-show="menuShow == 0">

    <!-- 底部树区域 -->
    <div :class="flag == 1 ? 'tree-active' : 'tree'">
      <div class="left">
        <img src="@/assets/icon/date-icon.png" alt="" />
        <img src="@/assets/icon/date-1990.png" alt="" />
        <img src="@/assets/icon/date-icon2.png" alt="" />
        <img src="@/assets/icon/date-2003.png" alt="" />
        <img src="@/assets/icon/date-2007.png" alt="" />
        <img src="@/assets/icon/book-bg2.png" alt="" />
        <img src="@/assets/icon/date-2014.png" alt="" />
        <img src="@/assets/icon/date-icon3.png" alt="" />
        <img src="@/assets/icon/date-2019.png" alt="" />
        <img src="@/assets/icon/date-icon4.png" alt="" />
        <img src="@/assets/icon/white.svg" alt="" />
      </div>
      <img class="tree-img" src="@/assets/icon/tree-icon.png" alt="" />
       <div :class="flag == 1 ? 'right-active' : 'right'">
        <p>{{ flag == 1 ? treeTestActive : treeText }}</p>
        <p>{{ flag == 1 ? treeTestActive2 : treeText2 }}</p>
        <img class="date-icon" src="@/assets/icon/date-icon5.png" alt="" />
        <p class="text">{{flag == 1 ? 'Book from the Ground Software' : '地书对话软件装置'}}</p>
        <img class="upright-icon" src="@/assets/icon/upright-icon.png" alt="" />
        <img class="date-icon2" src="@/assets/icon/date-icon6.png" alt="" />
        <p class="text2">{{flag == 1 ? 'Book from the Ground: From Point to Point Publication and Distribution' : '《地书：从点到点》 出版发行。'}}</p>
        <img class="upright-icon2" src="@/assets/icon/upright-icon2.png" alt="" />
        <img class="date-icon3"  src="@/assets/icon/date-icon7.png" alt="" />
        <p class="text3">{{ flag == 1 ? 'Book from the Ground Pop-up Book' : '地书立体书'}}</p>
        <p class="text4">{{flag == 1 ? treeTestActive3 : treeText3}}</p>
        <p class="text5">{{ flag == 1 ? treeTestActive4 : treeText4}}</p>
      </div>
    </div>
    <div class="box-bottom">
      <bottom :flag="flag"/>
    </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import menuPage from "@/components/menuPage.vue";
import bottom from "@/components/footer.vue";
export default {
  components: {
    navBar,
    menuPage,
    bottom
  },
  data() {
    return {
      classNum: 1,
      flag: 0,
      menuShow: 0,
      presentationText:
        "2012年，徐冰发行了世界上第一本仅通过公共标识与符号创作的书《地书:从点到点》。书中讲述了主人公小黑(卫生间标识当中代表男卫生间的小人符号)作为一个普通上班族的平凡的一天。徐冰在创作《地书》的过程中，通过排列组合的方式，创建了一套便于识读、符合逻辑的语言体系，完整的用符号讲述了一个故事。",
      presentationTextActive: 'In 2012, Xu Bing published the first book in the world that used public signs and symbols, Book from the Ground: From Point to Point. The book tells a story of the protagonist, “',
      presentationTextActive3: '”, (the human figure from the men’s restroom sign), a typical white-collar worker, and his ordinary life in a day. During the process of creating Book from the Ground, Xu Bing invented a consistent and logical language system that is easy to identify and understand, using comprehensive symbols to tell a story. MetaWords is a language system born of Book from the Ground.',
      presentationText2:
        "MetaWords是一套从《地书》当中孕育而来的语言系统， 所以《地书》作品本身对于MetaWords的发展来讲是一份十分重要的指导性文件。通过参考、了解《地书》的故事发生顺序以及符号使用方式，元宇宙的居民们可以自由自在地创作属于自己的MetaWords作品，甚至可以创造出超越现有的语言习惯的语法修辞，使得MetaWords可以完成创造元宇宙当中的元语言的使命。",
      presentationTextActive2:
        " The work, Book from the Ground, is a vital guiding document in the development of MetaWords. By referring to the storyline and the methods associated with use of its symbols, inhabitants in the Metaverse are imparted the freedom to create their own unique works with MetaWords. They can even invent new grammatical and rhetorical devices beyond existing linguistic conventions, which will forward the goal of MetaWords becoming the “Meta-language” within the Metaverse.",
      treeText:
        "徐冰发现机场内的各类标识用最简单的形式, 解决人与人之间的沟通问题。开始收集这些以识图方式设计的机场标识及航空公司的说明书。",
        treeTestActive: 'Xu Bing noticed that airport signage using the simplest method can help people communicate with one another more easily. He began to collect and organize airport signs and airplane safety guides that are designed with readable pictures.',
      treeText2:
        "从口香糖包装纸上的环保图案中获得用标识语言创作长篇故事的灵感，即着手创作《地书》。",
        treeTestActive2: 'Inspired by environmental protection images on gum wrappers where pictographs are used to narrate long-form stories, Xu started to make Book from the Ground.',
        treeText3: '《地书: 从点到点》 八种不同国家、地区语言的发行版本。',
        treeTestActive3: 'Book from the Ground published in languages from 8 different countries and regions.',
        treeText4: 'MetaWords是以NFT的形式在元宇宙(Metaverse)中延展的作品，旨在打造未来元宇宙中创造的全新语言体系，作为元语言随着元宇宙一起不断发展。',
         treeTestActive4: 'MetaWords, an NFT art project, is the evolution of Book from the Ground in the Metaverse. MetaWords aims to invent an entirely new language system of the future Metaverse–The “Meta-language” of the Metaverse.',
    };
  },
  mounted() {
    this.$refs.enu.classNum = 1
    this.flag = Number(this.$route.query.flag)
  },
  methods: {
    // 中英文切换
    language_active(type) {
      console.log(type, "type");
      if (type == "english") {
        this.flag = 1;
      } else {
        this.flag = 2;
      }
    },

    // 视频跳转播放
    jump() {
      window.open('https://metawords.s3.ap-southeast-1.amazonaws.com/MetaWords+from+Xu+Bing.mp4')
    },
    // 菜单按钮
    navBtn(data) {
      this.menuShow = data
    },
  },
};
</script>

<style lang="scss" scoped>
// 标题区域样式
.title-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  height: 30px;
  background: #000000;
  span {
    width: 89px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}
.content-wrap {
  background: url('../assets/icon/book-bg.png') no-repeat 0 70px;
  height: 1450px;
}
.content-active-wrap {
  background: url('../assets/icon/book-bg.png') no-repeat 0 70px;
  height: 1730px;
}
// 背景图片区域样式
.bg-img-wrap {
  margin-top: -20px;
  img {
    height: 247px;
    width: 306px;
  }
}

// 标题图片区域样式
.title-icon-wrap {
  text-align: center;
  span {
    width: 372px;
    height: 46px;
    font-size: 32px;
    font-family: NotoSansSC-Bold, NotoSansSC;
    font-weight: bold;
    color: #171826;
    line-height: 46px;
  }
  img {
    height: 46px;
    width: 306px;
  }
}

// 简介内容区域样式
.presentation-wrap {
  position: relative;
  padding: 40px 20px 0;
  font-size: 16px;
  font-family: NotoSansSC-Regular, NotoSansSC;
  font-weight: 400;
  color: #171826;
  p {
    line-height: 24px;
  }
  span {
    line-height: 24px;
  }
  .xiaoren-icon {
    // position: absolute;
    // top: 98px;
    // left: 128px;
    height: 15px;
    width: 7px;
  }
}

// 视屏播放区域样式
.video-wrap {
  position: relative;
  padding: 0 19px 0 18px;
  margin-top: 46px;
  img:nth-child(1) {
    height: 212px;
    width: 360px;
  }
  img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 57px;
    width: 57px;
  }
}

// 地书背景图片区域样式
.book-bg-wrap {
  padding: 0 17px 0 16px;
  margin-top: 38px;
  img {
    height: 266px;
    width: 365px;
  }
}

// 底部树区域样式
.tree-active {
  display: flex;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}
.tree {
  display: flex;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}
.left {
  margin-left: 30px;
    img {
      display: block;
    }
    img:nth-child(1) {
      margin: 70px 0px 0 30px;
      height: 38px;
      width: 93px;
    }
    img:nth-child(2) {
      height: 137px;
      width: 82px;
      margin: 11px 0px 0 35px;
    }
    img:nth-child(3) {
      margin: 60px 0px 0 15px;
      height: 37px;
      width: 103px;
    }
    img:nth-child(4) {
      margin: 10px 0px 0 10px;
      height: 33px;
      width: 113px;
    }
    img:nth-child(5) {
      margin: 88px 0px 0 0px;
      height: 100px;
      width: 123px;
    }
    img:nth-child(6) {
      margin: 65px 0px 0 10px;
      height: 82px;
      width: 108px;
    }
    img:nth-child(7) {
      height: 90px;
      width: 160px;
      margin: 43px 0 0 -28px;
    }
    img:nth-child(8) {
      margin: 34px 0px 0 15px;
      height: 37px;
      width: 103px;
    }
    img:nth-child(9) {
      margin: 10px 0px 0 20px;
      height: 110px;
      width: 110px;
    }
    img:nth-child(10) {
      margin: 17px 0px 0 20px;
      height: 37px;
      width: 96px;
    }
    img:nth-child(11) {
      margin: 9px 0px 0 20px;
      height: 93px;
      width: 93px;
    }
  }
.tree-img {
  height: 1246px;
  width: 53px;
}
.right {
    p {
      display: block;
      text-align: left;
      font-size: 14px;
      font-family: Montserrat-Medium, NotoSansSC;
      font-weight: 550;
      color: #171826;
      line-height: 18px;
    }
    p:nth-child(1) {
      width: 170px;
      height: 132px;
      margin-top: 120px;
      margin-left: 5px;
    }
    p:nth-child(2) {
      width: 170px;
      height: 100px;
      margin-top: 70px;
      margin-left: 5px;
    }
    
    img {
      display: block;
      margin-left: 10px;
      height: 37px;
      width: 100px;
    }
    .date-icon {
      margin-top: 60px;
    }
    .text {
      width: 170px;
      margin-top: 10px;
      margin-left: 5px;
    }
    .upright-icon {
      width: 4px;
      height: 60px;
      margin-left: 60px;
    }
    .date-icon2 {
      margin-top: 25px;
    }
    .text2 {
      margin-top: 10px;
      width: 176px;
      height: 44px;
    }
    .upright-icon2 {
      width: 4px;
      height: 30px;
      margin-left: 60px;
    }
    .date-icon3 {
      margin-top: 10px;
    }
    .text3 {
      width: 170px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .text4 {
      margin-top: 80px;
      width: 170px;
      height: 72px;
    }
    .text5 {
      margin-top: 60px;
      width: 170px;
      height: 154px;
    }
    
  }
.right-active {
    p {
      display: block;
      text-align: left;
      font-size: 14px;
      font-family: Montserrat-Medium, NotoSansSC;
      font-weight: 550;
      color: #171826;
      line-height: 18px;
    }
    p:nth-child(1) {
      width: 170px;
      height: 132px;
      margin-top: 70px;
      margin-left: 5px;
    }
    p:nth-child(2) {
      width: 170px;
      height: 100px;
      margin-top: 100px;
      margin-left: 5px;
    }
    
    img {
      display: block;
      margin-left: 10px;
      height: 37px;
      width: 100px;
    }
    .date-icon {
      margin-top: 90px;
    }
    .text {
      width: 170px;
      margin-top: 10px;
      margin-left: 5px;
    }
    .upright-icon {
      width: 4px;
      height: 30px;
      margin-left: 60px;
    }
    .date-icon2 {
      margin-top: 25px;
    }
    .text2 {
      margin-top: 10px;
      margin-left: 5px;
      width: 176px;
      height: 44px;
    }
    .upright-icon2 {
      width: 4px;
      height: 20px;
      margin-top: 30px;
      margin-left: 60px;
    }
    .date-icon3 {
      margin-top: 10px;
    }
    .text3 {
      width: 170px;
      margin-top: 10px;
      margin-left: 5px;
    }
    .text4 {
      margin-top: 30px;
      width: 170px;
      height: 72px;
    }
    .text5 {
      margin-top: 40px;
      width: 170px;
      height: 154px;
    }
    
  }
.box-bottom {
  margin-top: 110px;
}
</style>